import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/InvoiceE.vue'
import store from "../store"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/InvoiceE',
    name: 'InvoiceE',
    component: () =>
        import('../views/InvoiceE.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Company',
    name: 'Company',
    component: () =>
        import('../views/Company.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/CreditNote',
    name: 'CreditNote',
    component: () =>
        import('../views/Credit_note.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/DebitNote',
    name: 'DebitNote',
    component: () =>
        import('../views/Debit_note.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},

{
    path: '/Invoices',
    name: 'Invoices',
    component: () =>
        import('../views/Invoices.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/CreditNotes',
    name: 'CreditNotes',
    component: () =>
        import('../views/Invoices_nc.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/invoices_pos',
    name: 'invoices_pos',
    component: () =>
        import('../views/invoices_pos.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},

{
    path: '/inventory_status',
    name: 'inventory_status',
    component: () =>
        import('../views/inventory_status.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Resume',
    name: 'Resume',
    component: () =>
        import('../views/Store_resume.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Returns',
    name: 'Returns',
    component: () =>
        import('../views/Returns.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/WarrantyIn',
    name: 'WarrantyIn',
    component: () =>
        import('../views/Warranty_in.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/WarrantyOut',
    name: 'WarrantyOut',
    component: () =>
        import('../views/Warranty_out.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/StockImport',
    name: 'StockImport',
    component: () =>
        import('../views/Stock_import.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/StoctInReport',
    name: 'StoctInReport',
    component: () =>
        import('../views/Stock_in_report.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Promotion',
    name: 'Promotion',
    component: () =>
        import('../views/Promotion.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Warehouse',
    name: 'Warehouse',
    component: () =>
        import('../views/Warehouse.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/WarehouseReport',
    name: 'WarehouseReport',
    component: () =>
        import('../views/Warehouse_report.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Cash_report',
    name: 'Cash_report',
    component: () =>
        import('../views/Cash_report.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Receipt_in',
    name: 'Receipt_in',
    component: () =>
        import('../views/Receipt_in.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Receipt_out',
    name: 'Receipt_out',
    component: () =>
        import('../views/Receipt_out.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Supplier',
    name: 'Supplier',
    component: () =>
        import('../views/Supplier.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Events',
    name: 'Events',
    component: () =>
        import('../views/Get_events.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Rotation',
    name: 'Rotation',
    component: () =>
        import('../views/Stock_rotation.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Rotation_b',
    name: 'Rotation_b',
    component: () =>
        import('../views/Stock_rotation_b.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Vendors',
    name: 'Vendors',
    component: () =>
        import('../views/Vendors_stat.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/Inventory',
    name: 'Inventory',
    component: () =>
        import('../views/Inventory.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/G_search',
    name: 'G_search',
    component: () =>
        import('../views/Global_search.vue'),
    meta: {
        requiresAuth: true,
        is_admin: true
    }
},
{
    path: '/About',
    name: 'About',
    component: () =>
        import('../views/About.vue'),
    meta: {
        requiresAuth: false,
        is_admin: false
    }
},
{
    path: '/Login',
    name: 'Login',
    props: true,
    component: () =>
        import('../views/Login.vue'),
    meta: {
        requiresAuth: false,
        is_admin: false
    }
},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.profile) {
            if (store.state.profile.token) {
                next()
            } else {
                next({
                    name: 'Login',
                    params: { nextUrl: to.fullPath }
                })
            }
            return
        } else {
            next({
                name: 'Login',
                params: { nextUrl: to.fullPath }
            })
        }
    } else {
        next()
    }
})

export default router