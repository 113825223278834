export const webserver = (method, data, callback) => {
    var wsUrl = "https://ngmpos.co/ws/pos_posweb/" + method;
    //var wsUrl = "https://studioweb67.com/ws01/pos/" + method;
    var strData = JSON.stringify(data);
    const options = {
        method: 'POST',
        body: strData,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}

export const fe_ws = (method, data, callback) => {
    var wsUrl = "https://ngmpos.co/ws/fe_ws/" + method;
    var strData = JSON.stringify(data);
    const options = {
        method: 'POST',
        body: strData,
        headers: {
            'Content-Type': 'application/json'
        }
    }
    fetch(wsUrl, options)
        .then(res => res.json())
        .then(callback)
        .catch(err => console.log(err))
}


export const getToday = () => {
    var fullDate = new Date();
    var twoDigitMonth = fullDate.getMonth() + 1 + '';
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    return fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
}

export const getdays_ago = (days) => {
    var fullDate = new Date();
    fullDate.setDate(fullDate.getDate() + days);
    var twoDigitMonth = fullDate.getMonth() + 1 + '';
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    return fullDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate;
}

export const get_UUID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxx-xxxx-4xxx-yxxx-xxxx".replace(/[xy]/g, function(c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}